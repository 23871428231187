.BuildsListFeature .bi-check-circle {
    color: green;
}

.BuildsListFeature .bi-x-circle {
    color: red;
}

.BuildsListFeature .header-row {
    /* font-weight: bold; */
    cursor: pointer
}

.BuildsListFeature .up-arrow {
    font-weight: bold;
}

.BuildsListFeature .down-arrow {
    font-weight: bold;
}

.BuildsListFeature .row:hover {
    background-color: rgba(0,0,0,.05);
}

.BuildsListFeature .headline {
    text-align: left;
}