#TestFeature {
    text-align: left;
}

#TestFeature .results div {
    width: 40px;
    height: 40px;
    cursor: pointer;
    float: left;
    border: 1px solid black;
}

#TestFeature .results .Failure {
    background-color: red;
}

#TestFeature .results .Success {
    background-color: green;
}