#ExpandedSearch.hidden {
    max-height: 0;
    transition: max-height .25s ease-out;
    overflow: hidden;
}

#ExpandedSearch {
    max-height: 500px;
    transition: max-height .25s ease-in;
}

.site-link {
    color: black;
    text-decoration: none;
}

.nav-link,
.dropdown-toggle {
    color: black;
}

.search-button {
    margin-top: 20px;
}