.most-recent-builds td {
    height: 25px;
    width: 25px;
    border: 1px solid #CCC;
    cursor: pointer;
}

.most-recent-builds .success {
    background-color: green;
}

.most-recent-builds .fail {
    background-color: red;
}

.most-recent-builds {
    padding-top: 25px;
}