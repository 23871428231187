#TestRun {
    text-align: left;
}

#TestRun .target b {
    cursor: pointer;
}

#TestRun .subnav a {
    margin-right: 50px;
}

#TestRun .target .file {
    margin-left: 25px;
}

#TestRun .target .file .file-header {
    cursor: pointer;
}

#TestRun .target .file .file-body {
    font-family:'courier new',monospace;
}

#TestRun .target .file .file-body .executed span {
    background-color: #e0edfd;
}

#TestRun .target .file .file-body .not-executed span {
    background-color: #e6b0a5;
}

#TestRun .target .no-coverage {
    margin-left: 35px;
}